import React from "react";
import { Helmet } from "react-helmet"

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/landing2/Hero";
import { Feature1 } from "../sections/landing2/Feature1";
import Content1 from "../sections/landing2/Content1";
import Content2 from "../sections/landing2/Content2";
import Content7 from "../sections/landing2/Content7";
import Fact from "../sections/landing2/Fact";
import Offer from "../sections/landing2/Offer";
import CTA from "../sections/landing2/CTA";

const LandingPage2 = () => {
  return (
    <>
      <PageWrapper>
        <Helmet>
          <title>ShopWatch &amp; TownLink Systems</title>
          <meta name="description" content="A leading supplier of ShopWatch &amp; TownLink radio systems, ShopSafe offer effective and affordable crime reduction radio schemes throughout the UK." />
        </Helmet>
        <Hero />
        <Feature1 />
        <Fact />
        <Content1 />
        <Content2 />
        <Content7 />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default LandingPage2;